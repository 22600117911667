@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  height: 100%;
  width: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  /* colors from the lmnd logo */
  /* https://github.com/lem-onade */
  /* lemon flesh color */
  --lmnd-primary: #FFE175;
  /* lemon flesh highlights */
  --lmnd-primary-light: #FFFCA3;
  /* lemon flesh shadows */
  --lmnd-primary-dark: #D9C67E;
  /* lemon skin color */
  --lmnd-primary-skin: #FBD934;
  /* lemon pit color */
  --lmnd-primary-pit: #DDAB49;
}
