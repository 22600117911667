.App {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.Projects {
  display: flex;
}

.CurriculumVitae {
  display: flex;
}

.Header {
  display: flex;
}

.AsciiArt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
